import React from "react";
import useTheme from "src/hooks/useTheme";
import logoLight from "../../assets/images/SendFuse_Logo_Light.png";
import logoDark from "../../assets/images/SendFuse_Logo_Dark.png";
import useApp from "src/hooks/useAppContext";
import getHostName from "src/helpers/getAppFromHost";

interface HeaderNavBirddogMobileProps {
  props?: any;
}

const HeaderNavBirddogMobile = ({ props }: HeaderNavBirddogMobileProps) => {
  const { mode } = useTheme();

  const { app } = useApp();

  return (
    <>
      <header className="flex-no-wrap fixed z-10 flex min-w-full items-center justify-around bg-card-light p-3 dark:bg-card-dark">
        <div className="flex h-8 w-full items-center justify-center">
          <div className="mt-1 flex w-1/3 items-center justify-center">
            <img
              src={mode === "light" ? logoLight : logoDark}
              className=""
              alt={`${app?.name || getHostName()} Logo`}
            />
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderNavBirddogMobile;
