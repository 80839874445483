/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import jwt_decode from "jwt-decode";
import userApi from "src/api/user";
import useAlert from "src/hooks/useAlert";
import { AuthToken, RegisterInput } from "src/interfaces/user";
import { subscriptionApi } from "src/api";
import { useNavigate } from "react-router-dom";

const TOKEN_ID = "x-access-token";
const REGISTER_TOKEN_KEY = "register_token";

interface UIState {
  onCheckingSubscription: boolean;
}

interface AuthState extends Partial<UIState> {
  currentUser: AuthToken | null;
  subscription?: {
    plan: string;
    hasFailedPayments: boolean;
  };
  redirectTo?: string;
  isDemo?: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

export interface RegistrationResponse {
  success: boolean;
  token: string | null;
  error: string | null;
}
interface AuthContextValue extends AuthState {
  login: (
    email: string,
    password: string,
    rememberMe?: boolean
  ) => Promise<void>;
  logout: () => void;
  register: (data: RegisterInput) => Promise<RegistrationResponse>;
  setRedirection: (url: string) => void;
  checkSubscription: () => Promise<void>;
  setIsDemo: (isDemo: boolean) => void;
}

const initialAuthState: AuthState = {
  currentUser: null,
};

type InitialiseAction = {
  type: "INITIALIZE";
  payload: {
    currentUser: AuthToken | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    currentUser: AuthToken | null;
  };
};

type LogoutAction = {
  type: "LOGOUT";
  payload?: {};
};

type RegisterAction = {
  type: "REGISTER";
  payload: {
    currentUser: AuthToken | null;
  };
};

type RedirectAction = {
  type: "REDIRECT";
  payload: string;
};

type SetIsDemoAction = {
  type: "SET_IS_DEMO";
  payload: boolean;
};

type SetSubscriptionAction = {
  type: "SET_SUBSCRIPTION";
  payload: {
    plan: string;
    hasFailedPayments: boolean;
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | RedirectAction
  | SetSubscriptionAction
  | SetIsDemoAction;

const setSession = (token: string | null): void => {
  if (token) {
    // axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem(TOKEN_ID, token);
    // Clear registration token
    localStorage.setItem(REGISTER_TOKEN_KEY, "");
  } else {
    localStorage.setItem(TOKEN_ID, "");
    // delete axios.defaults.headers.common.Authorization;
  }
};

const decodeToken = (token: string | null) => {
  if (token) {
    const decoded: any = jwt_decode(token);
    const expireAt = new Date(decoded.exp * 1000);
    const currentTime = new Date();
    if (expireAt > currentTime) {
      return decoded.user;
    }
  }
  return null;
};

const handlers = {
  INITIALIZE: (state: AuthState, action: InitialiseAction): AuthState => {
    const { currentUser } = action.payload;
    return {
      ...state,
      currentUser,
    };
  },
  LOGIN: (state: AuthState, action: LoginAction) => {
    const { currentUser } = action.payload;
    return {
      ...state,
      currentUser,
    };
  },
  LOGOUT: (state: AuthState) => ({
    ...state,
    currentUser: null,
  }),
  REGISTER: (state: AuthState, action: RegisterAction) => {
    const { currentUser } = action.payload;
    return {
      ...state,
      currentUser,
    };
  },
  REDIRECT: (state: AuthState, action: RedirectAction) => {
    return {
      ...state,
      redirectTo: action.payload,
    };
  },
  SET_IS_DEMO: (state: AuthState, action: SetIsDemoAction) => {
    return {
      ...state,
      isDemo: action.payload,
    };
  },
  SET_SUBSCRIPTION: (state: AuthState, action: SetSubscriptionAction) => {
    return {
      ...state,
      subscription: action.payload,
    };
  },
};

const reducer = (state: AuthState, action: Action): AuthState =>
  // @ts-ignore
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () =>
    Promise.resolve({ success: false, token: "", error: "" }),
  setRedirection: () => {},
  checkSubscription: () => Promise.resolve(),
  setIsDemo: (isDemo: boolean) => {},
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { setAlert, clearAlert } = useAlert();
  const [onCheckingSubscription, setOnCheckingSubscription] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = localStorage.getItem(TOKEN_ID);
        const currentUser = decodeToken(token);

        if (currentUser) {
          dispatch({
            type: "INITIALIZE",
            payload: {
              currentUser,
            },
          });
        } else {
          setSession(null);
          dispatch({
            type: "INITIALIZE",
            payload: {
              currentUser: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            currentUser: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (
    email: string,
    password: string,
    rememberMe?: boolean
  ) => {
    try {
      const token = await userApi.login(email, password, rememberMe);
      const currentUser = decodeToken(token);

      setSession(token);

      dispatch({
        type: "LOGIN",
        payload: {
          currentUser,
        },
      });

      clearAlert();

      // navigate(location.pathname === '/' ? '/dashboard' : location.pathname);
    } catch (err: any) {
      console.error("App login: problem logging", err);
      setAlert({
        display: true,
        message: err?.message || "Unable to login",
        type: "error",
      });
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  const checkSubscription = async () => {
    try {
      const res = await subscriptionApi.checkSubscription();
      const { isDemo, ...rest } = res;
      dispatch({ type: "SET_SUBSCRIPTION", payload: { ...rest } });
      if (isDemo) {
        dispatch({ type: "SET_IS_DEMO", payload: isDemo });
      }
      setOnCheckingSubscription(false);
    } catch (err: any) {
      if (err.code === 401 && err.type === "subscription issue") {
        setAlert({
          display: true,
          message:
            "Your team account has a subscription issue. Please contact the support",
          type: "error",
        });
        logout();
      }
    }
  };

  const register = async (
    data: RegisterInput
  ): Promise<RegistrationResponse> => {
    try {
      const res: RegistrationResponse = await userApi.register(data);
      if (res.success) {
        const currentUser = decodeToken(res.token);

        setSession(res.token);

        dispatch({
          type: "REGISTER",
          payload: {
            currentUser,
          },
        });

        clearAlert();

        return res;
      } else {
        return res;
      }
    } catch (err: any) {
      console.error("App register: problem registering", err);
      setAlert({
        display: true,
        message: err?.message || "Unable to register",
        type: "error",
        duration: 20000,
      });
      return {
        success: false,
        token: "",
        error: "",
      };
    }
  };

  const setRedirection = useCallback((url: string) => {
    dispatch({
      type: "REDIRECT",
      payload: url,
    });
  }, []);

  const setIsDemo = useCallback((isDemo: boolean) => {
    dispatch({
      type: "SET_IS_DEMO",
      payload: isDemo,
    });
  }, []);

  useEffect(() => {
    if (state.currentUser) checkSubscription();
  }, [state.currentUser]);

  const navigate = useNavigate();

  const authChannelRef = useRef(new BroadcastChannel("auth_channel"));

  const authChannel = authChannelRef.current;

  useEffect(() => {
    authChannel.onmessage = (event: MessageEvent<any>) => {
      if (event.data.data === "logout") {
        setSession(null);
        dispatch({ type: "LOGOUT" });
        navigate("/login");
      }
    };
  }, [navigate]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        onCheckingSubscription,
        login,
        logout,
        register,
        checkSubscription,
        setRedirection,
        setIsDemo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
