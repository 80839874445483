import { Helmet } from "react-helmet";
import Spinner from "./components/loading/Spinner";
import Routes from "./routes";
import useApp from "./hooks/useAppContext";
import getHostName from "./helpers/getAppFromHost";
import store from "./redux/store";
import { Provider } from "react-redux";

function App() {
  const { app } = useApp();

  return (
    <>
      <Helmet>
        <title>{app?.name || getHostName()}</title>
      </Helmet>
      <Spinner />
      <Provider store={store}>
        <Routes />
      </Provider>
    </>
  );
}

export default App;
